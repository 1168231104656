import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
    const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the desired URL after 0 seconds (instantaneously)
    setTimeout(() => {
        window.location.href ='https://www.rishirao.org/song/index.html';
    }, 0);
  }, [navigate]);

    return (
        <div>
            <p>If you are not redirected, <a href="https://www.rishirao.org/song/index.html">click here</a>.</p>
        </div>
    );
};

export default Redirect;
